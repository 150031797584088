import {useApiCall} from "@jane/lib/src/api";
import {LearnComponent} from "@jane/lib/src/api/repositories/learn-repository";
import {FileInput, Input} from "@jane/lib/src/components/form/Input";
import {PillSelect} from "@jane/lib/src/components/form/PillSelect";
import React, {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "@jane/lib/src/components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const AddLearnCardModal: FC<{onFinished: () => void, moduleId: string, component: LearnComponent}> = (props) => {
  const {learn} = useApiCall(config)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('text')
  const [media, setMedia] = useState<File[]>([])
  const [mediaQuestions, setMediaQuestions] = useState(false)
  const [questionOptions, setQuestionOptions] = useState<string[]>([''])
  const [multipleChoice, setMultipleChoice] = useState(false)
  const [correctMultipleChoice, setCorrectMultipleChoice] = useState<number[]>([])
  const [openFields, setOpenFields] = useState<number[]>([])
  const [selectMultiple, setSelectMultiple] = useState<boolean>(false)
  const order = (props.component.cards?.reduce((acc, x) => Math.max(acc, x.order), 0) ?? 0) + 1
  const save = async () => {
    if (type === 'text') {
      await learn.addTextCard(props.moduleId, props.component.id, title, description)
    }
    if (type === 'question') {
      const card = await learn.addQuestionCard(props.moduleId, props.component.id, title, description, questionOptions, multipleChoice, correctMultipleChoice, selectMultiple, openFields)
    }
    if (type === 'media_question') {
      const card = await learn.addMediaQuestionCard(props.moduleId, props.component.id, title, description, questionOptions, multipleChoice, correctMultipleChoice, selectMultiple)
      if (!! media[0]) {
        await learn.addMediaToMediaQuestionCard(props.moduleId, props.component.id, card.id, title, description, media[0])
      }
    }
    if (type === 'media') {
      await learn.addMediaCard(props.moduleId, props.component.id, title, description, media[0])
    }
    if (type === 'open') {
      await learn.addOpenCard(props.moduleId, props.component.id, title, description)
    }
    props.onFinished()
  }

  const handleCheckboxChange = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!multipleChoice) {
        setMultipleChoice(true);
      }
      setCorrectMultipleChoice(prev => [...prev, i]);
    } else {
      setCorrectMultipleChoice(prev => {
        const updated = prev.filter(index => index !== i);
        if (updated.length === 0) {
          setMultipleChoice(false);
        }
        return updated;
      });
    }
  };

  const handleOpenFields = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOpenFields(prev => [...prev, i])
    } else {
      setOpenFields(prev => {
        return prev.filter(index => index !== i);
      });
    }
  };

  return <div className={"flex flex-col"}>
      <Input type={'text'} label={'Titel'} value={title} onChange={setTitle} />
      <PillSelect value={type} onChange={setType} options={{
        'text': "Tekstkaart",
        'media': "Beeldkaart",
        'question': "Vraagkaart",
        'open': "Open Vraagkaart",
        'media_question': "Vraagkaart + Beeld",
      }} />
      {['text', 'media', 'question', 'open', 'media_question'].includes(type) &&
        <Input type={'markdown'} maxWidth={'full'} label={type === 'question' || type === 'open' ? 'Vraag' : 'Omschrijving'} value={description} onChange={setDescription} />}

    {['question', 'media_question'].includes(type) &&
      <label className={"space-x-3 hover:cursor-pointer"}>
        <input type={'checkbox'} className={"accent-brand-500"} onChange={() => setSelectMultiple(!selectMultiple)}/>
        <span className={"text-sm"}>Meerdere goede antwoorden selecteerbaar</span>
      </label>
    }

    {['media', 'media_question'].includes(type) && (
      <div className={"flex flex-col items-start space-y-3 mt-2"}>
        <div className={"flex min-w-full"}>
          <FileInput onChange={setMedia} accept={'image/*,video/mp4'} label={'Media, Max. 32MB'}/>
        </div>
        <div>
            {['media_question'].includes(type) &&
              (<button className={'font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded'}
                       onClick={() => setMediaQuestions(true)}><FontAwesomeIcon icon={faPlus} className={"mr-1"}/>vragen toevoegen</button>)}
          </div>
        </div>
      )}

      {['media', 'media_question'].includes(type) && (
        <div className={"flex"}>
          {mediaQuestions && (<div className={"flex flex-col min-w-full"}>
              {questionOptions.map((option, i) => (<div key={i} className={"flex flex-col space-x-2"}>
                  <div className={"flex items-end space-x-2"}>
                    <Input type={'text'} label={`Antwoord ${i + 1}`} value={option} onChange={(newValue) =>
                      setQuestionOptions((old) => old.map((oldValue, oldI) => oldI === i ? newValue : oldValue))}/>
                    <IconButton disabled={questionOptions.length < 2} type={'danger'} size={'sm'} icon={faTimes}
                                onClick={() => setQuestionOptions((old) => old.filter((_, oldI) => oldI !== i))}/>
                  </div>
                  <label className={"space-x-3 hover:cursor-pointer"}>
                    <input type={'checkbox'} className={"accent-brand-500"} onChange={(event) => handleCheckboxChange(i, event)}/>
                    <span className={"text-sm"}>Dit is een correct antwoord.</span>
                  </label>
                  <label className={"space-x-3 hover:cursor-pointer"}>
                    <input type={'checkbox'} className={"accent-brand-500"} onChange={(event) => handleOpenFields(i, event)}/>
                    <span className={"text-sm"}>Heeft een open vraag veld.</span>
                  </label>
                </div>
              ))}
              {questionOptions.length < 8 && (<button
                className={'flex items-start my-2 font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded'}
                onClick={() => setQuestionOptions((old) => [...old, ''])}><FontAwesomeIcon icon={faPlus} className={"mr-1"} />Optie toevoegen</button>)}
            </div>
          )}
        </div>
      )}

    {type === 'question' && <div className={"mt-2 space-y-2"}>
      {questionOptions.map((option, i) => <div key={i} className={"flex flex-col space-x-2"}>
        <div className={"flex items-end space-x-2"}>
          <Input type={'text'} label={`Antwoord ${i + 1}`} value={option}
                 onChange={(newValue) => setQuestionOptions(old => old.map((oldValue, oldI) => oldI === i ? newValue : oldValue))}/>
          <IconButton disabled={questionOptions.length < 2} type={'danger'} size={'sm'} icon={faTimes}
                      onClick={() => setQuestionOptions(old => old.filter((_, oldI) => oldI !== i))}/>
        </div>
        <label className={"space-x-3 hover:cursor-pointer"}>
          <input type={'checkbox'} className={"accent-brand-500"} onChange={(event) => handleCheckboxChange(i, event)}/>
          <span className={"text-sm"}>Dit is een correct antwoord.</span>
        </label>
        <label className={"space-x-3 hover:cursor-pointer"}>
          <input type={'checkbox'} className={"accent-brand-500"} onChange={(event) => handleOpenFields(i, event)}/>
          <span className={"text-sm"}>Heeft een open vraag veld.</span>
        </label>
      </div>)}
      <button className={'font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded'}
              onClick={() => setQuestionOptions(old => [...old, ''])}><FontAwesomeIcon icon={faPlus} className={"mr-1"}/>Optie toevoegen</button>
    </div>}
    <ModalFooter text={'Opslaan'} isDisabled={(type === 'media' && media[0] === undefined) || description.length === 0 || title.length === 0} onSubmit={save} />
  </div>
}