import { useApiCall } from "@jane/lib/src/api";
import {LearnCard, LearnComponent} from "@jane/lib/src/api/repositories/learn-repository";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import {FileInput, Input} from "@jane/lib/src/components/form/Input";
import { PillSelect } from "@jane/lib/src/components/form/PillSelect";
import React, {FC, useEffect, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {IconButton } from "@jane/lib/src/components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Select } from "@jane/lib/src/components/form/Select";

export const EditLearnCardModal: FC<{onFinished: () => void, moduleId: string, component: LearnComponent, card: LearnCard}> = (props) => {
  const {learn} = useApiCall(config)
  const [title, setTitle] = useState(props.card.title)
  const [description, setDescription] = useState(props.card.description)
  const [type, setType] = useState(props.card.type)
  const [media, setMedia] = useState<File[]>([])
  const [mediaQuestions, setMediaQuestions] = useState(props.card.properties?.has_correct_option)
  const [questionOptions, setQuestionOptions] = useState<string[]>(props.card.properties?.options ?? [])
  const [hasCorrectMultipleChoice, setHasCorrectMultipleChoice] = useState(props.card.properties?.has_correct_option ?? false);
  const [correctMultipleChoice, setCorrectMultipleChoice] = useState<number[]>(Array.isArray(props.card.properties?.correct_option) ? props.card.properties.correct_option : [props.card.properties?.correct_option ?? 0]);
  const [selectMultiple, setSelectMultiple] = useState<boolean>(props.card.properties?.can_select_multiple ?? false)
  const [openFields, setOpenFields] = useState<number[]>(Array.isArray(props.card.properties?.open_fields) ? props.card.properties.open_fields : [props.card.properties?.open_fields ?? 0]);

  const save = async () => {
    if (type === 'text') {
      await learn.editTextCard(props.moduleId, props.component.id, props.card.id, title, description)
    }
    if (type === 'question') {
      await learn.editQuestionCard(props.moduleId, props.component.id, props.card.id, title, description, questionOptions, hasCorrectMultipleChoice, correctMultipleChoice, selectMultiple, openFields)
    }
    if (type === 'media_question') {
      const card = await learn.editMediaQuestionCard(props.moduleId, props.component.id, props.card.id, title, description, questionOptions, hasCorrectMultipleChoice, correctMultipleChoice, selectMultiple)
      if (!! media[0]) {
        await learn.addMediaToMediaQuestionCard(props.moduleId, props.component.id, card.id, title, description, media[0])
      }
    }
    if (type === 'media') {
      await learn.editMediaCard(props.moduleId, props.component.id, props.card.id, title, description, media[0])
    }
    {
      if (type === 'open') {
        await learn.editOpenCard(props.moduleId, props.component.id, props.card.id, title, description)
      }
    }
    props.onFinished()
  }
  useEffect(() => {
    setMedia([]);
  }, [type]);
  const handleCheckboxChange = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!hasCorrectMultipleChoice) {
        setHasCorrectMultipleChoice(true);
      }
      setCorrectMultipleChoice(prev => [...prev, i]);
    } else {
      setCorrectMultipleChoice(prev => {
        const updated = prev.filter(index => index !== i);
        if (updated.length === 0) {
          setHasCorrectMultipleChoice(false);
        }
        return updated;
      });
    }
  };
  const handleOpenFields = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOpenFields(prev => [...prev, i])
    } else {
      setOpenFields(prev => {
        return prev.filter(index => index !== i);
      });
    }
  };
  return <div className={"flex flex-col"}>
    <Input type={'text'} label={'Titel'} value={title} onChange={setTitle}/>
    <PillSelect value={type} onChange={setType}
                options={{
                  'text': "Tekstkaart",
                  'media': "Beeldkaart",
                  'question': "Vraagkaart",
                  'open': "Open Vraagkaart",
                  'media_question': "Vraagkaart + Beeld",
                }}/>
    {['text', 'media', 'question', 'open', 'media_question'].includes(type) && (
      <Input type={'markdown'} maxWidth={'full'} label={type === 'question' || type === 'open' ? 'Vraag' : 'Omschrijving'} value={description} onChange={setDescription}/>)}
    {['question', 'media_question'].includes(type) &&
      <label className={"space-x-3 hover:cursor-pointer"}>
        <input type={'checkbox'} className={"accent-brand-500"} checked={selectMultiple} onChange={(x) => setSelectMultiple(!selectMultiple)}/>
        <span className={"text-sm"}>Meerdere goede antwoorden selecteerbaar</span>
      </label>
    }
    {['media', 'media_question'].includes(type) && (<div className={"flex flex-col items-center space-y-2 mt-2"}>
      <div className={"flex min-w-full"}>
        <FileInput onChange={setMedia} accept={'image/*,video/mp4'} label={'Media (vervangen), Max. 32MB'}/>
      </div>
      <div>
        <button className={'font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded'}
                onClick={() => setMediaQuestions(true)}><FontAwesomeIcon icon={faPlus} className={"mr-1"}/>vragen toevoegen</button>
      </div>
      {mediaQuestions && (<div className={"flex flex-col min-w-full"}>
          {questionOptions.map((option, i) => (
            <div key={i} className="flex flex-col space-x-2">
              <div className="flex items-end space-x-2">
                <Input type="text" label={`Antwoord ${i + 1}`} value={option}
                       onChange={(newValue) => setQuestionOptions((old) => old.map((oldValue, oldI) => (oldI === i ? newValue : oldValue)))}/>
                <IconButton disabled={questionOptions.length < 2} type="danger" size="sm" icon={faTimes}
                            onClick={() => setQuestionOptions((old) => old.filter((_, oldI) => oldI !== i))}/>
              </div>
              <label className="space-x-3 hover:cursor-pointer">
                <input type="checkbox" className="accent-brand-500" checked={correctMultipleChoice.includes(i)} onChange={(event) => handleCheckboxChange(i, event)}/>
                <span className="text-sm">Dit is een correct antwoord.</span>
              </label>
              <label className={"space-x-3 hover:cursor-pointer"}>
                <input type={'checkbox'} className={"accent-brand-500"} checked={openFields.includes(i)} onChange={(event) => handleOpenFields(i, event)}/>
                <span className={"text-sm"}>Heeft een open vraag veld.</span>
              </label>
            </div>
          ))}
          {questionOptions.length < 5 && (<button
            className={'flex items-start my-2 font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded'}
            onClick={() => setQuestionOptions((old) => [...old, ''])}><FontAwesomeIcon icon={faPlus} className={"mr-1"} />Optie toevoegen</button>)}
        </div>
      )}
    </div>)}
    {['question', 'media_question'].includes(type) && (
      <div className={"mt-2 space-y-2"}>
        {questionOptions.map((option, i) => (
          <div key={i} className="flex flex-col space-x-2">
            <div className="flex items-end space-x-2">
              <Input type="text" label={`Antwoord ${i + 1}`} value={option}
                     onChange={(newValue) => setQuestionOptions((old) => old.map((oldValue, oldI) => (oldI === i ? newValue : oldValue)))}/>
              <IconButton disabled={questionOptions.length < 2} type="danger" size="sm" icon={faTimes}
                          onClick={() => setQuestionOptions((old) => old.filter((_, oldI) => oldI !== i))}/>
            </div>
            <label className="space-x-3 hover:cursor-pointer">
              <input type="checkbox" className="accent-brand-500" checked={correctMultipleChoice.includes(i)} onChange={(event) => handleCheckboxChange(i, event)}/>
              <span className="text-sm">Dit is een correct antwoord.</span>
            </label>
            <label className={"space-x-3 hover:cursor-pointer"}>
              <input type={'checkbox'} className={"accent-brand-500"} checked={openFields.includes(i)} onChange={(event) => handleOpenFields(i, event)}/>
              <span className={"text-sm"}>Heeft een open vraag veld.</span>
            </label>
          </div>
        ))}
        <button className="font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded"
                onClick={() => setQuestionOptions((old) => [...old, ''])}><FontAwesomeIcon icon={faPlus} className="mr-1"/>Optie toevoegen</button>
      </div>
    )}
    <ModalFooter text={'Opslaan'} isDisabled={description.length === 0 || title.length === 0} onSubmit={save}/>
  </div>
}
