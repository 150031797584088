import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { Button } from "@jane/lib/src/components/form/Button"
import MDEditor from '@uiw/react-md-editor';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faPencil,
  faPlus, faSave,
  faTimes, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../../config";
import { Loading } from "@jane/lib/src/components/Loading";
import {LearnCard, LearnComponent } from "@jane/lib/src/api/repositories/learn-repository";
import React, {FC, useEffect, useState} from "react";
import {useModal} from "../../components/ModalProvider";
import {useNavigate, useParams} from "react-router-dom";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { SectionDivider } from "@jane/lib/src/components/content/SectionDivider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EditLearnComponentModal} from "../../modals/EditLearnComponentModal";
import { Breadcrumbs } from "@jane/lib/src/components/content/Breadcrumbs";
import { EditLearnCardModal } from "../../modals/EditLearnCardModal";
import { AddLearnCardModal } from "../../modals/AddLearnCardModal";
import {ConfirmationModal} from "../../modals/ConfirmationModal";
import { useAuth } from "@jane/lib/src/auth/hook";

export const LearnComponentDetailPage: React.FC = () => {
  const {learn} = useApiCall(config)
  const {moduleId, componentId} = useParams()
  const {resource: module, isLoading, reload} = useApiEndpoint(() => learn.readModule(moduleId!), moduleId !== undefined)
  const resource = module?.components?.find(x => x.id === componentId)
  const editModal = useModal({title: "Onderdeel bewerken", body: <EditLearnComponentModal moduleId={moduleId!} module={module!} component={resource!} onFinished={() => reload()} />})

  return <ContentContainer>
    <Loading loading={resource === undefined}>
      <Breadcrumbs currentPage={'Onderdeel: ' + resource?.name??''} crumbs={[
        {label: 'Kennisbank', href: '/learn'},
        {label: 'Onderwerp: ' + module?.name ?? '', href: `/learn/${moduleId}`},
      ]} />
      <div className={'flex items-center'}>
        <h1 className={"h-12 flex items-center text-2xl leading-tight font-bold"}>Onderdeel: {resource?.name}</h1>
        <div className={"flex-1 flex justify-end"}>
          <Button type={'secondary'} size={'sm'} text={'Onderdeel bewerken'} icon={faPencil} onClick={() => editModal.open()}/>
        </div>
      </div>
      <SectionDivider />
      <section>
        <CardEditor moduleId={moduleId!} component={resource!} cards={resource?.cards ?? []} reload={reload} />
      </section>
    </Loading>
  </ContentContainer>
}

const CardEditor: FC<{component: LearnComponent, moduleId: string, reload: () => void, cards: LearnCard[]}> = props => {
  const sortCardsByOrder = (a: LearnCard, b: LearnCard) => a.order > b.order ? 1 : -1
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const addModal = useModal({title: "Nieuwe kaart", body: <AddLearnCardModal moduleId={props.moduleId} component={props.component} onFinished={() => props.reload()} />, size: "lg"})
  const [editingCards, setEditingCards] = useState(props.cards.sort(sortCardsByOrder))
  const {learn} = useApiCall(config)
  useEffect(() => {
    setEditingCards([...props.cards].sort(sortCardsByOrder))
  }, [props.cards]);
  const startEdit = () => {
    setEditingCards([...props.cards].sort(sortCardsByOrder))
    setMode('edit');
  }
  const cancelEdit = () => {
    setEditingCards([...props.cards].sort(sortCardsByOrder))
    setMode('view');
  }
  const saveEdit = async () => {
    const newlyOrderedCards = editingCards.map((card, i) => ({...card, order: i+1}))
    await learn.editCardsOrder(props.moduleId, props.component.id, newlyOrderedCards)
    props.reload()
    setMode('view');
  }
  const switchCards = (a: number, b: number) => {
    setEditingCards(old => {
      return old.map((card, i, allCards) => {
        if (i === a) return {...allCards[b]}
        if (i === b) return {...allCards[a]}
        return {...card}
      })
    })
  }
  return <section>
    <div className={"flex items-start justify-between"}>
      <div className={"flex items-center mb-4 space-x-4"}>
        <div className={"-mb-4"}>
          <SectionHeader>Kaarten</SectionHeader>
        </div>
        {mode === 'view' ? <>
          <Button type={'secondary'} size={'sm'} text={'Bewerken'} icon={faEdit} onClick={() => startEdit()}/>
        </> : <>
          <Button type={'primary'} size={'sm'} text={'Volgorde opslaan'} icon={faSave} onClick={() => saveEdit()}/>
          <Button type={'secondary'} size={'sm'} text={'Sluiten'} icon={faTimes} onClick={() => cancelEdit()}/>
        </>}
      </div>
      <Button type={'primary'} size={'sm'} text={'Kaart toevoegen'} icon={faPlus} onClick={() => addModal.open()}/>
    </div>
    <div className={"grid grid-cols-3 gap-8"}>
      {(mode === 'view' ? props.cards.sort(sortCardsByOrder) : editingCards).map((card, i) => ({...card, order: i+1})).map((card, i, list) => {
        const isFirst = i === 0
        const isLast = i === list.length - 1
        return <CardPreview
          reload={props.reload}
          moduleId={props.moduleId}
          component={props.component}
          card={card}
          key={card.id}
          onMoveUp={!isFirst && mode === 'edit' ? () => switchCards(i, i-1) : undefined}
          onMoveDown={!isLast && mode === 'edit' ? () => switchCards(i, i+1) : undefined}
          canEdit={mode === 'edit'}
          canDelete={mode === 'edit'}
        />;
      })}
    </div>
  </section>
}

const CardPreview: FC<{
  moduleId: string,
  component: LearnComponent,
  card: LearnCard,
  canEdit: boolean,
  canDelete: boolean,
  onMoveUp?: () => void,
  onMoveDown?: () => void
  reload: () => void
}> = props => {
  const {learn} = useApiCall(config)
  const {token} = useAuth()
  const editModal = useModal({title: "Kaart bewerken", body: <EditLearnCardModal moduleId={props.moduleId} component={props.component} card={props.card} onFinished={() => props.reload()} />})
  const deleteModal = useModal({title: "Kaart verwijderen", body: <ConfirmationModal text={'Weet je zeker dat je deze kaart wilt verwijderen?'} onConfirmed={async () => {
      await learn.deleteCard(props.moduleId, props.component.id, props.card.id)
      props.reload()
    }} />})
  return <div
    className={`bg-brand-primary-container text-brand-on-primary-container min-h-72 border-2 ${(props.onMoveUp || props.onMoveDown) ? 'border-brand-primary' : 'border-brand-primary-container'} px-4 py-4 rounded-xl flex flex-col items-stretch relative`}>
    <div className={"flex items-center justify-between"}>
      <span className={"h-6 w-6 border border-brand-primary rounded-full flex items-center justify-center"}>{props.card.order}</span>
      <h3 className={'font-light leading-tight'}>{typeToString(props.card.type)}</h3>
    </div>
    <div className={"flex-1"}>
      <h2 className={'text-xl font-bold mt-4 mb-3 leading-tight'}>{props.card.title}</h2>
      {(props.card.type === 'media' || props.card.type === 'media_question') && !!props.card.media && <>
        {props.card.properties?.mimetype?.startsWith('image') && <img src={`${props.card.media ?? ''}&accessToken=${token}`} alt={props.card.title} className={'w-full mb-4 h-48 object-cover rounded-lg'} />}
        {props.card.properties?.mimetype?.startsWith('video') && <video src={`${props.card.media ?? ''}&accessToken=${token}`} controls={true} autoPlay={true} muted={true} loop={true} className={'w-full mb-4 h-48 object-cover rounded-lg'} />}
      </>}
      {props.card.description.length > 0 && <div data-color-mode={"light"} className={'mb-4'}>
        <style>{`
          .wmde-markdown { background-color: transparent !important; }
          .wmde-markdown table tr { background-color: transparent !important; }
          .wmde-markdown table td,th { border-color: #24292f20 !important; }
        `}</style>
        <MDEditor.Markdown source={props.card.description} />
      </div>}
      {(props.card.type === 'question' || props.card.type === 'media_question') && (
        <div className="space-y-2 mb-4">
          {((props.card.properties?.options as string[] | undefined) ?? []).map((option, i) => {
            let isCorrectOption = false;
            if (props.card.properties.has_correct_option) {
              if (Array.isArray(props.card.properties.correct_option)) {
                isCorrectOption = props.card.properties.correct_option.includes(i);
              } else {
                isCorrectOption = props.card.properties.correct_option === i;
              }
            }
            return (<div key={i} className={`flex items-center justify-center text-center py-2 px-8 w-full relative border-2 ${isCorrectOption ? 'border-green-400 bg-green-50 text-green-900' : 'border-brand-300'} rounded text-sm`}>
                {option}
                {isCorrectOption && (<div className="h-5 w-5 absolute top-1/2 rounded-full left-2 -translate-y-1/2 bg-green-700 text-white flex items-center justify-center"><FontAwesomeIcon icon={faCheck} /></div>)}
              </div>);
          })}
        </div>
      )}
    </div>
    <div className={"flex flex-col items-stretch space-y-2"}>
      {props.canEdit ? <Button type={'primary'} size={'xs'} text={'Bewerken'} icon={faPencil} onClick={() => editModal.open()}/> : <></>}
      {props.canDelete ? <Button type={'primary'} size={'xs'} text={'Verwijderen'} icon={faTrash} onClick={() => deleteModal.open()}/> : <></>}
    </div>
    {props.onMoveUp ? <button className={"h-6 w-6 absolute top-1/2 -translate-y-1/2 -left-3 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"} onClick={props.onMoveUp}><FontAwesomeIcon icon={faChevronLeft} /></button> : <></>}
    {props.onMoveDown ? <button className={"h-6 w-6 absolute top-1/2 -translate-y-1/2 -right-3 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"} onClick={props.onMoveDown}><FontAwesomeIcon icon={faChevronRight} /></button> : <></>}
  </div>
}

function typeToString(type: string) {
  switch (type) {
    case 'text':
      return 'Tekstkaart'
    case 'image':
    case 'media':
    case 'video':
      return 'Beeldkaart'
    case 'question':
      return 'Vraagkaart'
    case 'open':
      return 'Open Vraagkaart'
    case 'media_question':
      return 'Vraagkaart + Beeld'
    default:
      return 'Onbekend'
  }
}

